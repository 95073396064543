<template>
  <div>
    <div class="house-name" v-if="houseNameList.length > 0">
      <span class="name-item" v-for="(item, index) in houseNameList" :key="item.value" @click="houseNameSelect(index)">
        <span style="text-decoration: underline">{{item.label}}</span><span v-if="index != houseNameList.length - 1"> - </span>
      </span>
    </div>
    <div class="house-search">
      <input v-model="searchValue" placeholder="请输入关键字搜索" @keyup.enter="onSearch" type="search" value="搜索" class="search-content" />
      <img v-if="searchValue" :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <img class="search-icon" :src="require('@/assets/img/search.png')" />
    </div>
    <div class="house-select">
      <template v-if="selectList.length > 0">
        <div class="select-item" v-for="item in selectList" :class="{'selected': houseNameList[selectIndex] && item.value == houseNameList[selectIndex].value}"
             :key="item.value" @click="houseSelect(item)">{{item.label}}</div>
      </template>
      <div v-else class="empty">暂无数据</div>
    </div>
    <div style="display: flex; justify-content: space-between">
      <div class="btn" @click="houseConfirm">确定</div>
      <div class="btn" style="background-color: #fff; border: 1px solid #4581F8; color: #4581F8" @click="houseReset">重置</div>
    </div>
  </div>
</template>

<script>
import {getBelongCommunity, getbelongSubArea, getHouseList} from '@/utils/common'
export default {
  data () {
    return {
      searchValue: '',
      houseNameList: [],
      orgList: [],
      selectList: [],
      _selectList: [],
      selectIndex: 0,
      depth: '',
      dataForm: {
        orgId: '',
        buildingIds: '',
        unitId: '',
        houseId: ''
      }
    }
  },
  created() {
    this.depth = this.$globalData.userInfo.depth
    this.dataForm.orgId = this.$globalData.userInfo.orgId
    this.getDataList()
  },
  methods: {
    getDataList () {
      if (this.depth == 4) {
        getBelongCommunity (this.dataForm.orgId, (e) => {
          e = e.map(item => {
            return {
              value: e.id,
              label: e.name,
              ...item,
              depth: 0
            }
          })
          this.selectList = e
          this._selectList = e
          this.orgList = e
        })
      } else {
        getbelongSubArea (this.dataForm.orgId, (e) => {
          e = e.map(item => {
            return {
              value: e.id,
              label: e.name,
              ...item,
              depth: 1
            }
          })
          this.selectList = e
          this._selectList = e
          this.orgList = e
        })
      }
    },
    onSearch () {
      this.selectList = JSON.parse(JSON.stringify(this._selectList))
      this.selectList = this.selectList.filter(item => item.label.indexOf(this.searchValue) > -1)
    },
    onCancel () {
      this.searchValue = ''
      this.selectList = JSON.parse(JSON.stringify(this._selectList))
    },
    houseNameSelect (index) {
      if (index != this.houseNameList.length - 1 || index == 0) {
        this.searchValue = ''
        this.selectIndex = index
        if (index == 0) {
          this.selectList = this.orgList
          this._selectList = this.orgList
        } else if (index != this.houseNameList.length - 1) {
          this.selectList = this.houseNameList[index - 1].children
          this._selectList = this.houseNameList[index - 1].children
        }
        this.houseNameList = this.houseNameList.slice(0, index + 1)
      }
    },
    houseSelect (house) {
      this.searchValue = ''
      this.$set(this.houseNameList, this.selectIndex, house)
      // house.depth  0-社区 1-小区 2-楼幢 3-单元 4-房屋
      if (house.depth == 0) {
        getbelongSubArea (this.houseNameList[0].value, (e) => {
          e = e.map(item => {
            return {
              value: e.id,
              label: e.name,
              ...item,
              depth: 1
            }
          })
          this.selectIndex++
          this.houseNameList[this.selectIndex - 1].children = e
          this.selectList = e
          this._selectList = e
        })
      } else if (house.depth != 4) { // 选择楼幢
        getHouseList(house.value, house.depth, (e) => {
          this.selectIndex++
          this.houseNameList[this.selectIndex - 1].children = e
          this.selectList = e
          this._selectList = e
        })
      }
    },
    houseConfirm () {
      this.dataForm = {
        orgId: this.$globalData.userInfo.orgId,
        buildingIds: '',
        unitId: '',
        houseId: ''
      }
      this.houseNameList.map(item => {
        if (item.depth == 0 || item.depth == 1) {
          this.dataForm.orgId = item.value
        } else if (item.depth == 2) {
          this.dataForm.buildingIds = item.value
        } else if (item.depth == 3) {
          this.dataForm.unitId = item.value
        } else if (item.depth == 4) {
          this.dataForm.houseId = item.value
        }
      })
      // console.log(this.houseNameList)
      this.$emit('houseConfirm', this.dataForm,this.houseNameList)
    },
    houseReset () {
      this.searchValue = ''
      this.houseNameList = []
      this.selectIndex = 0
      this.dataForm = {
        orgId: this.$globalData.userInfo.orgId,
        buildingIds: '',
        unitId: '',
        houseId: ''
      }
      this.getDataList()
      this.$emit('houseConfirm', this.dataForm)
    }
  }
}
</script>

<style scoped lang="scss">
.van-popup {
  padding-top: 24px;
  border-radius: 16px 16px 0 0;
}
.house-name {
  width: 750px;
  height: 88px;
  padding: 24px 30px;
}
.name-item {
  color: #4581F8;
  font-size: 30px;
  line-height: 42px;
}
.house-search {
  position: relative;
  margin: 24px auto;
  width: 690px;
  height: 64px;
  background: rgba(0,0,0,0.04);
  border-radius: 8px;
  .search-content {
    font-size: 28px;
    padding-top: 16px;
    color: #666;
    padding-left: 52px;
    display: block !important;
    width: 560px !important;
    margin-left: 30px !important;
    background-color: transparent;
    border: none;
  }
  .search-content::placeholder {
    color: #999;
  }
  .search-clear {
    position: absolute;
    top: 16px;
    right: 24px;
    width: 32px;
    height: 32px;
  }
  .search-icon {
    position: absolute;
    top: 16px;
    left: 24px;
    width: 32px;
    height: 32px;
  }
}
.house-select {
  padding: 0 12px 0 30px;
  max-height: 402px;
  overflow: auto;
}
.select-item {
  width: 218px;
  margin: 0 18px 22px 0;
  line-height: 74px;
  background: #F5F5F5;
  color: #666;
  font-size: 28px;
  text-align: center;
  display: inline-block;
  border-radius: 12px;
  padding: 0 0;
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.selected {
  background: #E9EFFD;
  color: #4581F8;
  position: relative;
}
.selected::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 32px;
  height: 34px;
  background-image: url('~@/assets/img/selected-icon.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 999;
}
.btn {
  width: 330px;
  line-height: 88px;
  background: #4581F8;
  border-radius: 8px;
  font-size: 30px;
  color: #fff;
  margin: 20px auto 20px;
  text-align: center;
}
.empty {
  color: #999;
  font-size: 30px;
  width: 100%;
  text-align: center;
  margin: 40px 0;
}
</style>
